/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import withTranslate from '../hoc/withTranslate';
import {
  customerSelectorStyles,
  customerListItemStyles
} from '../jss/customerSelectorStyles';
import { Box, InputAdornment } from '@mui/material';
import IconUtil from './util/IconUtil';
import TextUtil from './util/FormatTextUtil';
import IconUtils from './util/IconUtil';
import CommonUtil from './util/CommonUtil';

export const CustomerSelector = ({
  options,
  label,
  hideLabelContainer = false,
  enableErrorText = true,
  disabled = false,
  meta,
  input,
  t,
  id,
  selectCustomer,
  selectedCustomer,
  clearSelectedCustomer,
  clearCustomerFields,
  placeholder,
  renderInputAdornment = false,
  displayAddNewOption = false
}) => {
  const hasError = meta && meta.error && (meta.touched || meta.submitFailed);
  const { onChange } = input;

  return (
    <div className='textField'>
      {!hideLabelContainer &&
      <div className='textFieldInfo'>
        <label>{label}</label>
      </div>}
      <Box
        className={`textFieldHolder customerSelector ${hasError ? 'hasError' : ''}`}
        sx={customerSelectorStyles}
      >
        <Autocomplete
          id={id}
          name={input.name}
          disableClearable={false}
          freeSolo={true}
          disabled={disabled}
          selectOnFocus={true}
          clearOnBlur={false}
          handleHomeEndKeys={true}
          clearIcon={IconUtil.getIcon('Close', '#888C8D', 24)}
          isOptionEqualToValue={(option, value) => option.id === selectedCustomer?.id}
          options={options}
          onChange={(event, newValue, reason) => {
            if (typeof newValue === 'string') {
              onChange([newValue]);
              clearSelectedCustomer();
              clearCustomerFields();
            } else if (newValue && newValue.inputValue && typeof newValue.inputValue === 'string') {
              onChange([newValue.inputValue]);
              clearSelectedCustomer();
              clearCustomerFields();
            } else if (newValue?.email?.length > 0) {
              onChange([newValue.email]);
              selectCustomer(newValue.index);
            } else if (newValue?.phoneNumber?.length > 0) {
              onChange([]);
              selectCustomer(newValue.index);
            } else {
              onChange([]);
              clearSelectedCustomer();
            }

            if (reason === 'clear') {
              clearCustomerFields();
            }

          }}
          onBlur={(event) => {
            const newValue = event.target.value;
            if (typeof newValue === 'string' && newValue !== '') {
              const newEmail = newValue.trim().toLowerCase();
              const customerIndex = options.findIndex(option => option.email === newEmail);
              if (customerIndex < 0) {
                onChange([newValue]);
                clearSelectedCustomer();
                clearCustomerFields();
              } else {
                const differentCustomer = selectedCustomer && selectedCustomer.email?.toLowerCase() !== newEmail;
                if (!selectedCustomer || differentCustomer) {
                  selectCustomer(customerIndex);
                }
              }
            }
          }}
          filterOptions={(options, params) => {
            const { inputValue } = params;

            const filtered = options.filter((option) => {
              const fullName = typeof option.fullName === 'string' ? option.fullName : '';
              const email = typeof option.email === 'string' ? option.email : '';
              const phoneNumber = typeof option.phoneNumber === 'string' ? option.phoneNumber : '';

              const searchValue = inputValue.toLowerCase();

              return (
                fullName.toLowerCase().includes(searchValue) ||
                email.toLowerCase().includes(searchValue) ||
                phoneNumber.toLowerCase().includes(searchValue)
              );
            });

            const isExisting = options.some((option) => {
              const fullName = typeof option.fullName === 'string' ? option.fullName : '';
              const email = typeof option.email === 'string' ? option.email : '';
              const phoneNumber = typeof option.phoneNumber === 'string' ? option.phoneNumber : '';

              const searchValue = inputValue.toLowerCase();

              return (
                fullName.toLowerCase() === searchValue ||
                email.toLowerCase() === searchValue ||
                phoneNumber.toLowerCase() === searchValue
              );
            });

            if (displayAddNewOption && inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                email: <strong>{`${t('Add')} "${inputValue}"`}</strong>,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }

            if (option.inputValue) {
              return option.inputValue;
            }

            if (typeof option.email === 'string') {
              return JSON.stringify({index: option.index});
            }

            return JSON.stringify({index: option.index});
          }}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box component={'li'} sx={customerListItemStyles}
                   key={key} {...optionProps}>
                <div className={'customerInformation'}>
                  <div className={'fullNameContainer'}>
                    <span className={'fullName'}>{option.fullName}</span>
                    <span>{option.email}</span>
                  </div>
                  <div
                    className={'phone'}>{TextUtil.formatPhoneNumber(option.phoneNumber)}
                  </div>
                </div>
                <div className={'paymentMethodContainer'}>
                  <div className={'reward'}>
                    {option.reward && IconUtils.getIcon('LoyaltyProgramIcon', '#888C8D', 20)}
                  </div>
                  <div className={'paymentMethod'}>
                    {option.paymentMethod && IconUtil.getIcon('CreditCardIcon', '#888C8D')}
                  </div>
                </div>
              </Box>
            );
          }}
          renderInput={(params) => {
            const { InputProps, inputProps, ...rest } = params;
            const formattedValue = !CommonUtil.isNumeric(inputProps.value) && CommonUtil.isJSON(inputProps.value)
              ? JSON.parse(inputProps.value)
              : inputProps.value;

            const getFormattedValue = (options, value) => {
              if (options.length > 0 && value !== '') {
                return (typeof formattedValue === 'object') ? options[formattedValue.index].email : formattedValue;
              } else {
                return (formattedValue !== '') ? formattedValue : '';
              }
            }

            const inputAdornment = () => {
              return !!InputProps.endAdornment
                ? InputProps.endAdornment
                : renderInputAdornment && <InputAdornment position='end' disablePointerEvents={true}>
                  {IconUtil.getIcon('SelectCust', '#888C8D', 28)}
                </InputAdornment>
            }

            return (
              <TextField
                {...rest}
                placeholder={placeholder}
                InputProps={{
                  ...InputProps,
                  endAdornment: inputAdornment()
                }}
                inputProps={{
                  ...inputProps,
                  maxLength: 50,
                  value: getFormattedValue(options, formattedValue)
                }}
                error={enableErrorText && hasError}
              />
            );
          }}
          value={(input.value?.[0] && options.find(option => option.id === selectedCustomer?.id)) || input.value?.[0] || null}
        />
      </Box>
      {hasError && <span>{t ? t(meta.error) : meta.error}</span>}
    </div>
  );
};

export default withTranslate(CustomerSelector);
